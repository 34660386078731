import React, { Fragment } from 'react';

import {
  BooleanField,
  Button,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  Labeled,
  List,
  SelectArrayInput,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
  useRecordContext
} from 'react-admin';
import { Chip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { CopyUserEmail, CopyUserId, GetAvailableBags } from "../utils";
import { AdminPagination } from 'src/common/AdminPagination';
import { userRoles } from 'src/utils/index';

const UsersFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Email" source="email" alwaysOn />
      <TextInput label="Id" source="id" />
      <TextInput label="First Name" source="firstName" alwaysOn/>
      <TextInput label="Last Name" source="lastName" alwaysOn />
      <TextInput label="Phone" source="phoneNumber" alwaysOn />
      <SelectArrayInput label="Roles" source="roles" choices={userRoles} resettable={"true"} alwaysOn />
    </Filter>
  );
}

const LastLoginDate = () => {
  const record = useRecordContext();

  if (record && record.lastLoginDate) {
    return (
      <DateField source="lastLoginDate" showTime label="Last Login" />
    );
  } else {
    return <div>Never logged in! 😂</div>;
  }
};

const UserPanel = () => {
  return (
    <Fragment>
      <GetAvailableBags />
      <Labeled label="Last login Date: ">
        <LastLoginDate />
      </Labeled>
    </Fragment>
  );
};

export const CopyUserPhone = () => {
  const record = useRecordContext();

  return (
    <Button
      style={{ minWidth: '32px' }}
      sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }}}
      onClick={() => navigator.clipboard.writeText(record?.phoneNumber)}
    >
      <ContentCopyIcon fontSize='small' />
    </Button>
  );
}

const UserListActions = () => {
  const { permissions } = usePermissions();

  return (
    <TopToolbar>
      <CreateButton />
      {permissions === 'SUPER_ADMIN' && (<ExportButton maxResults={99999999} />)}
    </TopToolbar>
  );
};

const UserList = (props) => {
  return (
    <List
      pagination={<AdminPagination />}
      perPage={25}
      {...props}
      filters={<UsersFilter />}
      actions={<UserListActions />}
    >
      <Datagrid
        expand={<UserPanel />}
        bulkActionButtons={false}
        rowClick={false}
      >
        <FunctionField
          render={(record) => {
            return (
              <Fragment>
                <CopyUserId />
                {record?.id.slice(0, 8) + '...'}
              </Fragment>
            );
          }}
          label="User ID"
        />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <div label="Actions">
          <EditButton variant="contained" />
        </div>
        <FunctionField
          render={(record) => {
            return (
              <Fragment>
                <CopyUserEmail />
                <TextField source="email" />
              </Fragment>
            );
          }}
          label="Email Address"
        />
        <FunctionField
          render={(record) => {
            return (
              <Fragment>
                <CopyUserPhone />
                {record?.phoneNumber}
              </Fragment>
            );
          }}
          label="Phone Number"
        />
        <BooleanField source="emailVerified" label='Verified' />
        <FunctionField
          render={(record) => {
            return userRoles.map((role, index) => {
              return record.roles.some(recordRoleId => recordRoleId === role.id) && <Chip key={record.id + index} label={role.name} size="medium"/>;
            })
          }}
          sortable={false}
          label="Roles"
        />
        <DateField source="createdAt" showTime />
        <FunctionField
          render={(record) => {
            return (
              record?.lastLoginDate ? record.lastLoginDate : "Never logged in! 😂"
            );
          }}
          label="Last Login"
          sortable={false}
        />
      </Datagrid>
    </List>
  )
};

export default UserList;